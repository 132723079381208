<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="$store.state.caregiverCalendar.isTaskBar"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $store.commit('caregiverCalendar/SET_ISTASKBAR', val)"
    >
      <template #default="{}">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            <!-- <span>Shift Details</span> -->
            <span>Shift Calendar - {{ $store.state.caregiverCalendar.shift ? $store.state.caregiverCalendar.calendarOptions.filter(f => f.id == $store.state.caregiverCalendar.shift.extendedProps.status).map(f => f.label)[0] : '' }}</span>
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="$store.commit('caregiverCalendar/SET_ISTASKBAR', false)"
            />
          </div>
        </div>

        <!-- Body -->

        <!-- Tasks Form -->
        <b-form
          class="p-2"
        >
          <div>
            <b-form-group v-if="$store.state.caregiverCalendar.shift">
              <b-row
                v-if="![1, 2, 3, 4, 5, 10, '1', '2', '3', '4', '5', '10'].includes($store.state.caregiverCalendar.shift.extendedProps.status)"
                id="sidebar-warning"
                class="mb-1 mx-0 py-1 bg-light-secondary text-danger"
              >
                <b-col
                  cols="1"
                  class=""
                >
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="20"
                    class="text-danger"
                  />
                </b-col>
                <b-col
                  cols="10"
                >
                  <!-- <span v-if="$store.state.caregiverCalendar.shift.extendedProps.status === '3'">
                    This shift was marked as
                    <span
                      v-if="$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafe === 'yes'"
                      class="text-primary"
                    >SAFE</span>
                    <span
                      v-else
                      class="text-danger"
                    >NOT SAFE</span>.
                  </span> -->
                  <span
                    v-if="$store.state.caregiverCalendar.shift.extendedProps.status === '6' || $store.state.caregiverCalendar.shift.extendedProps.status === 6"
                    class="text-danger"
                  >
                    Admin will assign a caregiver that matches the client's preferences.
                  </span>
                  <span
                    v-if="$store.state.caregiverCalendar.shift.extendedProps.status === '7' || $store.state.caregiverCalendar.shift.extendedProps.status === 7"
                    class="text-danger"
                  >
                    This shift was cancelled by the client or the Family PIC.
                  </span>
                  <span
                    v-if="$store.state.caregiverCalendar.shift.extendedProps.status === '8' || $store.state.caregiverCalendar.shift.extendedProps.status === 8"
                    class="text-danger"
                  >
                    This shift was cancelled by the caregiver. Admin will assign a new caregiver.
                  </span>
                </b-col>
              </b-row>
              <!-- shift details -->
              <span class="info-primary">Shift Details</span>
              <div>
                <!-- client -->
                <div class="d-flex mt-1">
                  <span class="mr-1">
                    <b-avatar
                      size="34"
                      :src="$store.state.caregiverCalendar.shift.extendedProps.clientPicture ? `${url}upload/clients/${$store.state.caregiverCalendar.shift.extendedProps.clientPicture}` : ''"
                      variant="primary"
                      :text="`${$store.state.caregiverCalendar.shift.extendedProps.client_fullname.split(', ')[1].charAt(0)}${$store.state.caregiverCalendar.shift.extendedProps.client_fullname.split(', ')[0].charAt(0)}`"
                    />
                  </span>
                  <span>
                    <span class="info-primary">
                      {{ `${$store.state.caregiverCalendar.shift.extendedProps.client_fullname.split(', ')[1]} ${$store.state.caregiverCalendar.shift.extendedProps.client_fullname.split(', ')[0]}` }}
                    </span><br>
                    <span class="text-muted info-secondary">
                      Client
                    </span>
                  </span>
                </div>
                <!-- caregiver -->
                <!-- <div
                  class="mt-1"
                >
                  <div
                    v-if="$store.state.caregiverCalendar.shift.extendedProps.status !== '6' && $store.state.caregiverCalendar.shift.extendedProps.caregiver !=='0'"
                    class="d-flex"
                  >
                    <span class="mr-1">
                      <b-avatar
                        size="34"
                        :src="$store.state.caregiverCalendar.shift.extendedProps.clientPicture ? `${url}upload/${$store.state.caregiverCalendar.shift.extendedProps.caregiver}/${$store.state.caregiverCalendar.shift.extendedProps.clientPicture}` : ''"
                        variant="primary"
                        :text="`${$store.state.caregiverCalendar.shift.extendedProps.caregiver_firstname.charAt(0)}${$store.state.caregiverCalendar.shift.extendedProps.caregiver_lastname.charAt(0)}`"
                      />
                    </span>
                    <span>
                      <span class="info-primary">
                        {{ `${$store.state.caregiverCalendar.shift.extendedProps.caregiver_firstname} ${$store.state.caregiverCalendar.shift.extendedProps.caregiver_lastname}` }}
                      </span><br>
                      <span class="text-muted info-secondary">
                        Caregiver
                      </span>
                    </span>
                  </div>
                  <div
                    v-else
                    class="d-flex"
                  >
                    <span class="mr-1">
                      <b-avatar
                        size="34"
                        variant="light-secondary"
                      />
                    </span>
                    <span>
                      <span class="info-primary">
                        Open Shift
                      </span><br>
                      <span class="text-muted info-secondary">
                        No Caregiver Assigned
                      </span>
                    </span>
                  </div>
                </div> -->
                <!-- start date -->
                <div class="d-flex mt-1">
                  <span class="mr-1 f-icon-container">
                    <span class="m-auto">
                      <feather-icon
                        icon="CalendarIcon"
                        size="20"
                        class="f-icon"
                      />
                    </span>
                  </span>
                  <span>
                    <span class="info-primary">
                      {{ moment($store.state.caregiverCalendar.shift.start).format('ddd, MMMM DD, YYYY') }}
                    </span><br>
                    <span class="text-muted info-secondary">
                      {{ `${moment($store.state.caregiverCalendar.shift.extendedProps.start_time, ["HH.mm"]).format("hh:mm a")} - ${moment($store.state.caregiverCalendar.shift.extendedProps.end_time, ["HH.mm"]).format("hh:mm a")}` }}
                    </span>
                  </span>
                </div>
                <!-- location -->
                <div class="d-flex mt-1">
                  <span class="mr-1 f-icon-container">
                    <span class="m-auto">
                      <feather-icon
                        icon="MapPinIcon"
                        size="20"
                        class="f-icon"
                      />
                    </span>
                  </span>
                  <span class="my-auto">
                    <span
                      v-if="$store.state.caregiverCalendar.shift.extendedProps.location === ''"
                      class="info-primary"
                    >
                      N/A
                    </span>
                    <span
                      v-else
                      class="info-primary"
                    >
                      {{ $store.state.caregiverCalendar.shift.extendedProps.location }}
                    </span>
                  </span>
                </div>
              </div>

              <hr class="my-1">

              <!-- Tasks  -->
              <b-list-group>
                <span class="info-primary mb-1 ">Tasks</span>
                <b-list-group-item
                  v-for="taskOption in $store.state.caregiverCalendar.shift.extendedProps.shift_tasks"
                  :key="taskOption.id"
                  class="text-truncate"
                >
                  <div>
                    <div>
                      <div
                        v-if="taskOption.description"
                        class="d-flex align-items-center tasks"
                      >
                        <b-icon-check-circle
                          v-if="taskOption.status === 'completed'"
                          class="rounded-circle task_check"
                          variant="light"
                        />
                        <!-- <b-img
                          v-else-if="['not-completed', 'wont-complete'].includes(taskOption.status)"
                          :src="require('@/assets/images/icons/tick_incomplete.svg')"
                          class="b-icon"
                        /> -->
                        <b-icon-x-circle-fill
                          v-else-if="['not-completed', 'wont-complete'].includes(taskOption.status)"
                          font-size="18"
                          variant="danger"
                        />
                        <b-icon-circle-fill
                          v-else
                          class="rounded-circle bg-light-secondary"
                          variant="light"
                        />
                        <span class="mr-1">
                          {{ stripTags(taskOption.description) }}
                        </span>
                      </div>
                      <div
                        v-else
                        class="d-flex align-items-center tasks"
                      >
                        <b-icon-circle-fill
                          class="rounded-circle bg-light-secondary"
                          color="#B1BAC4"
                        />
                        <span class="text-wrap">{{ taskOption.task }}</span>
                      </div>
                      <div v-if="$store.state.caregiverCalendar.shift.extendedProps.status === '3' && taskOption.notes !== ''">

                        <div class="d-flex pl-0 align-items-center tasks pl-1">
                          <span class="mr-1 f-icon-container ">
                            <span class="m-auto">
                              <feather-icon
                                icon="Edit3Icon"
                                size="20"
                                class="f-icon"
                              />
                            </span>
                          </span>
                          <span>
                            <span class="">
                              <span
                                class="info-primary"
                              >
                                <span class="text-wrap text-muted">
                                  {{ taskOption.notes }}
                                </span>
                              </span>
                              <!-- <span
                                v-if="taskOption.notes === ''"
                                class="info-primary"
                              >
                                N/A
                              </span> -->
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div></b-list-group-item>
              </b-list-group>

              <div v-if="$store.state.caregiverCalendar.shift.extendedProps.status === '3'">

                <!-- <div class="d-flex pl-0 align-items-center tasks">
                  <span class="mr-1 f-icon-container ">
                    <span class="m-auto">
                      <feather-icon
                        icon="Edit3Icon"
                        size="20"
                        class="f-icon"
                      />
                    </span>
                  </span>
                  <span>
                    <span class="">
                      <span
                        v-for="mileageNote in taskObj.notes.value"
                        :key="mileageNote"
                        class="info-primary"
                      >
                        <span>
                          {{ mileageNote }}
                        </span><br>
                      </span>
                      <span
                        v-if="taskObj.notes.value.length === 0"
                        class="info-primary"
                      >
                        N/A
                      </span>
                    </span>
                  </span>
                </div> -->

                <hr class="my-1">
                <div>
                  <!-- Mileage  -->
                  <span class="info-primary">Mileage</span>
                  <div class="d-flex mt-1 align-items-center tasks">
                    <span class="mr-1 f-icon-container">
                      <span class="m-auto">
                        <!-- <feather-icon
                          icon="DollarSignIcon"
                          size="20"
                          class="f-icon"
                        /> -->
                        <b-img
                          :src="require('@/assets/images/icons/drive_icon.svg')"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="info-primary">
                        <span v-if="taskObj.transpo.value.includes('drive')">Drive - {{ parseFloat(taskObj.mileageDrive.value).toFixed(1) }} miles </span><br>
                        <span v-if="taskObj.transpo.value.includes('commute')">Commute - {{ parseFloat(taskObj.mileageCommute.value).toFixed(1) }} miles </span>
                      </span>
                    </span>
                  </div>
                  <div class="d-flex mt-1 align-items-center tasks">
                    <span class="mr-1 f-icon-container ">
                      <span class="m-auto">
                        <feather-icon
                          icon="Edit3Icon"
                          size="20"
                          class="f-icon"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="">
                        <span
                          v-for="mileageNote in taskObj.mileageNotes.value"
                          :key="mileageNote"
                          class="info-primary"
                        >
                          <span class="text-wrap">
                            {{ mileageNote }}
                          </span><br>
                        </span>
                        <span
                          v-if="taskObj.mileageNotes.value.length === 0"
                          class="info-primary"
                        >
                          N/A
                        </span>
                      </span>
                    </span>
                  </div>

                </div>
                <hr class="my-1">
                <!-- Expenses -->
                <div>
                  <span class="info-primary">Expenses</span>
                  <div class="d-flex mt-1 align-items-center tasks">
                    <span class="mr-1 f-icon-container">
                      <span class="m-auto">
                        <feather-icon
                          icon="DollarSignIcon"
                          size="20"
                          class="f-icon"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="info-primary">
                        $ {{ parseFloat2Decimals($store.state.caregiverCalendar.shift.extendedProps.clock_inout.expenseamount) }}
                      </span>
                    </span>
                  </div>
                  <div class="d-flex mt-1 align-items-center tasks">
                    <span class="mr-1 f-icon-container">
                      <span class="m-auto">
                        <feather-icon
                          icon="Edit3Icon"
                          size="20"
                          class="f-icon"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="info-primary text-wrap">
                        {{ $store.state.caregiverCalendar.shift.extendedProps.clock_inout.expensenote ==="" ? "N/A" : $store.state.caregiverCalendar.shift.extendedProps.clock_inout.expensenote }}
                      </span>
                    </span>
                  </div>
                </div>
                <hr
                  v-show="$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto !== ''"
                  class="my-1"
                >
                <!-- WorkSafe -->
                <!--
                <div>
                  <span class="info-primary">WorkSafe</span>
                  <div
                    v-if="$store.state.caregiverCalendar.shift.extendedProps.extendedProps ? ($store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafe === 'yes') : false"
                    class="d-flex mt-1 align-items-center tasks"
                  >
                    <span class="mr-1 f-icon-container">
                      <span class="m-auto">
                        <feather-icon
                          icon="BriefcaseIcon"
                          size="20"
                          class="f-icon"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="info-primary">
                        Safe
                      </span>
                    </span>
                  </div>
                  <div class="d-flex mt-1 align-items-center tasks">
                    <span class="mr-1 f-icon-container">
                      <span class="m-auto d-flex">
                        <feather-icon
                          icon="Edit3Icon"
                          size="20"
                          class="f-icon"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="info-primary text-wrap ">
                        {{ $store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafecomment ==="" ? "N/A" : $store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafecomment }}
                      </span>
                    </span>
                  </div>
                </div>
                <hr class="my-1">
                -->
                <!-- Photos -->
                <div>
                  <span
                    v-show="$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto !== ''"
                    id="photos"
                    class="info-primary mb-1"
                  >Photos</span>
                  <div class="mt-1">
                    <!-- :src="`data:image/png;base64, ${$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto}`" -->
                    <!-- imageUrl: `data:image/png;base64, ${$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto}`, -->
                    <b-img
                      v-if="$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto !== ''"
                      :src="`${url}api/mobile/worksafe/${$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto}`"
                      thumbnail
                      fluid
                      @click="$store.dispatch('caregiverCalendar/popupNotif', {
                        padding: '1em 3em',
                        imageUrl: `${url}api/mobile/worksafe/${$store.state.caregiverCalendar.shift.extendedProps.clock_inout.worksafephoto}`,
                        showConfirmButton: false,
                        showCloseButton: true,
                        returnFocus: false,
                      })"
                    />
                  </div>
                </div>
              </div>

              <!-- v-if="['1', '6'].includes($store.state.caregiverCalendar.shift.extendedProps.status) && $store.state.caregiverCalendar.shift.extendedProps.shift_type === 'future'" -->
              <!-- {{ $store.state.caregiverCalendar.shift.id }} -->
              <b-button
                v-if="($store.state.caregiverCalendar.shift.extendedProps.status === '1' || $store.state.caregiverCalendar.shift.extendedProps.status === 1) && $store.state.caregiverCalendar.shift.extendedProps.shift_type === 'future' "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-cancel-shift
                class="btn mt-3"
                variant="danger"
              >
                Cancel Shift
              </b-button>

              <!-- <b-button
                v-else-if="$store.state.caregiverCalendar.shift.extendedProps.status === '6'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-request-shift
                class="mt-3"
                variant="primary"
              >
                Request this Shift
              </b-button>

              <b-button
                v-else-if="$store.state.caregiverCalendar.shift.extendedProps.status === '10'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-cancel-request
                class="mt-3"
                variant="danger"
                @click="cancelRequestedShiftModal = true"
              >
                Cancel Request
              </b-button> -->

              <b-button
                v-else-if="$store.state.caregiverCalendar.shift.extendedProps.status === '6' || $store.state.caregiverCalendar.shift.extendedProps.status === 6"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-3"
                variant="secondary"
                @click="requestShiftModal = true"
              >
                Request this Shift
              </b-button>

              <b-button
                v-else-if="$store.state.caregiverCalendar.shift.extendedProps.status === '10' || $store.state.caregiverCalendar.shift.extendedProps.status === 10"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-3"
                variant="danger"
                @click="cancelRequestedShiftModal = true"
              >
                Cancel Request
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-3 w-25"
                variant="secondary"
                @click="$store.commit('caregiverCalendar/SET_ISTASKBAR', false)"
              >
                OK
              </b-button>

            </b-form-group>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <!-- warning cancel modal  -->
    <b-modal
      id="modal-cancel-shift"
      v-model="cancelShiftModal"
      title="Cancel Shift"
      centered
    >
      <div class="d-flex mt-1">
        <feather-icon
          class="m-auto text-warning"
          icon="AlertCircleIcon"
          size="85"
        />
      </div>
      <div class="warning-popup text-center mt-1">
        <span class="warning-primary">
          ARE YOU SURE?
        </span><br>
        <span class="warning-secondary">
          You want cancel this shift?
        </span>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel() "
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          :disabled="$store.state.caregiverCalendar.isLoading"
          @click="cancelShift"
        >
          Continue
        </b-button>

      </template>
    </b-modal>

    <!-- warning cancel modal  -->
    <b-modal
      id="modal-cancel-request"
      v-model="cancelRequestedShiftModal"
      title="Cancel Requested Shift"
      centered
    >
      <div class="d-flex mt-1">
        <feather-icon
          class="m-auto text-warning"
          icon="AlertCircleIcon"
          size="85"
        />
      </div>
      <div class="warning-popup text-center mt-1">
        <span class="warning-primary">
          ARE YOU SURE?
        </span><br>
        <span class="warning-secondary">
          You want to cancel this shift request?
        </span>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel() "
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          :disabled="$store.state.caregiverCalendar.isLoading"
          @click="cancelRequestShift"
        >
          Continue
        </b-button>

      </template>
    </b-modal>

    <!-- warning request modal  -->
    <b-modal
      id="modal-request-shift"
      v-model="requestShiftModal"
      title="Request Shift"
      centered
    >
      <div class="d-flex mt-1">
        <feather-icon
          class="m-auto text-warning"
          icon="AlertCircleIcon"
          size="85"
        />
      </div>
      <div class="warning-popup text-center mt-1">
        <span class="warning-primary">
          Request this shift?
        </span>
        <br>
        <!-- <span class="warning-secondary">
          You are about to request this shift.
        </span> -->
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          :disabled="$store.state.caregiverCalendar.isLoading"
          @click="requestShift"
        >
          Continue
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BAvatar,
  BButton,
  BModal,
  BRow,
  BCol,
  BIconCheckCircle,
  BIconCircleFill,
  BIconXCircleFill,
  BImg,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BButton,
    BModal,
    BRow,
    BCol,
    BIconCheckCircle,
    BIconCircleFill,
    BIconXCircleFill,
    BImg,
  },
  directives: {
    Ripple,
  },
  setup() {
    const eventLocal = ref({})

    const cancelShiftModal = ref(false)

    const cancelRequestedShiftModal = ref(false)

    const taskObj = {
      transpo: ref([]), mileageCommute: ref(0), mileageDrive: ref(0), mileageNotes: ref([]), mileageTranspo: ref(''), notes: ref([]),
    }

    const requestShiftModal = ref(false)

    const showShiftTasks = ref(computed(() => ['1', '6', '7', '8'].includes(store.state.caregiverCalendar.shift.extendedProps.status) && store.state.caregiverCalendar.shift.extendedProps.shift_type === 'future')) /* displays shift tasks */

    const cancelShift = async () => {
      store.commit('caregiverCalendar/SET_LOADING', true)
      // console.log('store.state.caregiverCalendar.shift', store.state.caregiverCalendar.shift.startStr)
      const data = JSON.stringify({
        action: 'cancell-shift',
        type: 'cancelled_by_caregiver',
        userId: JSON.parse(localStorage.userData).id, /* current user id */
        shift_id: store.state.caregiverCalendar.shift.id, /* shift id */
        shiftDate: moment(store.state.caregiverCalendar.shift.startStr).format('YYYY-MM-DD'), /* date to be excluded */
        user: 'caregiver',
        caregiver_id: store.state.caregiverCalendar.shift.extendedProps.caregiver,
        caregiver_name: `${store.state.caregiverCalendar.shift.extendedProps.caregiver_firstname} ${store.state.caregiverCalendar.shift.extendedProps.caregiver_lastname}`,
        client_id: store.state.caregiverCalendar.shift.extendedProps.client,
        client_name: store.state.caregiverCalendar.shift.extendedProps.client_name,
      })
      const formData = new FormData()

      formData.append('data', data)

      try {
        const res = await store.dispatch('caregiverCalendar/request', formData)
        if (res.data.response.status === 200) {
          store.dispatch('caregiverCalendar/getShifts') /* all get shift  */
          store.dispatch('caregiverCalendar/popupNotif', {
            icon: 'success',
            title: 'Saved!',
            text: 'Shift cancelled succesfully.',
            showConfirmButton: false,
            timer: 1500,
          })
          cancelShiftModal.value = false
          store.commit('caregiverCalendar/SET_LOADING', false)
          store.commit('caregiverCalendar/SET_ISTASKBAR', false)
          store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.notificationSockId, sockMsg: true })
          store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.alertSockId, sockMsg: true })
        }
      } catch (error) {
        // console.log(error)
        store.dispatch('caregiverCalendar/popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    }

    const cancelRequestShift = async () => {
      // console.log(moment(store.state.caregiverCalendar.shift.startStr).format('YYYY-MM-DD'))
      store.commit('caregiverCalendar/SET_LOADING', true)
      // console.log('store.state.caregiverCalendar.shift', store.state.caregiverCalendar.shift.startStr)
      const data = JSON.stringify({
        action: 'cancel-request-shift',
        userId: JSON.parse(localStorage.userData).id, /* current user id */
        shift_id: store.state.caregiverCalendar.shift.id, /* shift id */
        shiftDate: moment(store.state.caregiverCalendar.shift.startStr).format('YYYY-MM-DD'), /* date to be excluded */
        caregiver_id: store.state.caregiverCalendar.shift.extendedProps.caregiver,
      })
      const formData = new FormData()

      formData.append('data', data)

      try {
        const res = await store.dispatch('caregiverCalendar/request', formData)
        if (res.data.response.status === 200) {
          // console.log(res.data.response)
          store.dispatch('caregiverCalendar/getShifts') /* all get shift  */
          store.dispatch('caregiverCalendar/popupNotif', {
            icon: 'success',
            title: 'Updated!',
            text: 'Requested shift cancelled succesfully.',
            showConfirmButton: false,
            timer: 2000,
          })
          cancelRequestedShiftModal.value = false
          store.commit('caregiverCalendar/SET_LOADING', false)
          store.commit('caregiverCalendar/SET_ISTASKBAR', false)
          store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.notificationSockId, sockMsg: true })
          store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.alertSockId, sockMsg: true })
        }
      } catch (error) {
        // console.log(error)
        store.dispatch('caregiverCalendar/popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    }

    const requestShift = async () => {
      store.commit('caregiverCalendar/SET_LOADING', true)
      const data = JSON.stringify({
        action: 'request-shift',
        type: 'pending_confirmation',
        userId: JSON.parse(localStorage.userData).id, /* current user id */
        shift_id: store.state.caregiverCalendar.shift.id, /* shift id */
        shift_date: moment(store.state.caregiverCalendar.shift.startStr).format('YYYY-MM-DD'), /* date to be excluded */
        user: 'caregiver',
        caregiver_id: store.state.caregiverCalendar.shift.extendedProps.caregiver,
        client_id: store.state.caregiverCalendar.shift.extendedProps.client,
        client_name: store.state.caregiverCalendar.shift.extendedProps.client_name,
      })

      const formData = new FormData()

      formData.append('data', data)

      try {
        const res = await store.dispatch('caregiverCalendar/request', formData)
        if (res.data.response.status === 200) {
          store.dispatch('caregiverCalendar/getShifts') /* all get shift  */
          store.dispatch('caregiverCalendar/popupNotif', {
            icon: 'success',
            title: 'Saved!',
            text: 'Shift requested succesfully.',
            showConfirmButton: false,
            timer: 1500,
          })
          requestShiftModal.value = false
          store.commit('caregiverCalendar/SET_LOADING', false)
          store.commit('caregiverCalendar/SET_ISTASKBAR', false)
          store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.notificationSockId, sockMsg: true })
          // store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.alertSockId, sockMsg: true })
        }
      } catch (error) {
        // console.log(error)
        store.dispatch('caregiverCalendar/popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    }

    const setTasksDetails = tasks => {
      // console.log(tasks)
      // console.log(store.state.caregiverCalendar.shift.extendedProps.clock_inout.id)
      taskObj.mileageCommute.value = 0
      taskObj.mileageDrive.value = 0
      taskObj.mileageNotes.value = []
      taskObj.notes.value = []

      if (tasks.length > 0) {
        tasks.forEach(task => {
          if (task.description) {
            // taskObj.mileage.value += parseFloat(task.mileage)
            if (task.mileage_notes !== '') {
              taskObj.mileageNotes.value.push(task.mileage_notes)
            }
            if (task.notes !== '') {
              taskObj.notes.value.push(task.notes)
            }
            // eslint-disable-next-line no-nested-ternary
            if (task.mileage_transpo.toLowerCase() === 'driving') {
              taskObj.mileageDrive.value += parseFloat(task.mileage)
              taskObj.transpo.value.push('drive')
            } else {
              taskObj.mileageCommute.value += parseFloat(task.mileage)
              taskObj.transpo.value.push('commute')
            }
          }
        })
      }
    }

    const shiftTasks = ref(computed(() => (store.state.caregiverCalendar.shift ? store.state.caregiverCalendar.shift.extendedProps.shift_tasks : [])))

    watch(shiftTasks, tasks => {
      if (tasks.length > 0) {
        setTasksDetails(tasks)
      }
    })

    return {
      eventLocal,
      stripTags: html => {
        const tmp = document.implementation.createHTMLDocument('New').body
        tmp.innerHTML = html
        return tmp.textContent || tmp.innerText || ''
      },
      parseFloat2Decimals: value => parseFloat(value).toFixed(2),
      imgProp: {
        center: true,
        blankColor: '#777',
        // width: 100,
        // height: 100,
        class: 'mt-1',
      },
      moment,
      url: Vue.prototype.$mainUrl,
      taskStatus: {
        inprogress: 'primary',
        completed: 'success',
        'wont-complete': 'danger',
        'not-completed': 'warning',
        future: 'info',
      },
      cancelShift,
      cancelRequestShift,
      cancelShiftModal,
      cancelRequestedShiftModal,
      requestShift,
      requestShiftModal,
      showShiftTasks,
      taskObj,
    }
  },
  unmounted() {
    this.style.remove()
  },
}
</script>

<style scoped lang="scss">
  [dir] .list-group-item{
    border: none !important;
    span{
        font-size: 16px;
        font-weight: 500;
    }
  }
  [dir] .no-data-text{
      font-size: 20px;
      font-weight: 500;
  }

  [dir] {
      font-size: 15px;
      .info-key{
          font-weight: 500;
      }
  }
  [dir] .f-icon{
    color: #569099;
  }

  [dir] .f-icon-container{
    width: 34px;
    height: 34px;
    background: #F4F8F9 0% 0% no-repeat padding-box;
    display: flex;
    border-radius: 5px;
  }

  [dir] .info-primary{
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    /* color: #33475B !important; */
  }

  [dir] .info-secondary{
    font-weight: 400;
    line-height: 15px;
    font-size: 12px;
    color: #98A1AB;
  }
  [dir] .btn-primary {
    background: #569099 0% 0% no-repeat padding-box;
    border-radius: 5px;
  }
  [dir] .warning-popup{
    .warning-primary{
      font-size: 20px;
      line-height: 22px;
      font-weight: bold;
    }
    .warning-secondary{
      font-size: 16px;
      line-height: 18x;
      font-weight: lighter;
    }
  }
  [dir] .badge{
    font-weight: 400;
    line-height: 10px;
    font-size: 9px;
  }
  [dir] .list-group-item {
    padding: 0;
  }

[dir] #sidebar-warning{
  /* svg{
    color: #33475B !important;
  } */
  // background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: normal normal medium 14px/20px Montserrat;
  font-weight: 500;
  letter-spacing: 0px;
  /* color: #33475B !important; */
}

[dir] .task_check{
  background: #B9D23E;
}

[dir] .tasks{
  padding-left: 5px;
  padding-bottom: 5px;
  svg.b-icon, .b-icon{
    margin-right: 10px;
    font-size: 20px;
  }
  .bg-light-secondary{
    circle{
      background: #B1BAC4  !important;
    }
  }
}
[dir] #photos.img{
  width: 100% !important;
}
[dir] form{
  overflow-x: hidden;
}
</style>
