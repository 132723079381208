<template>
  <div>
    <b-row class="my-1">
      <b-col cols="9">
        <h1>{{ pageTitle }}</h1>
      </b-col>

      <b-col
        cols="3"
      >
        <v-select
          id="client-filter"
          v-model="client"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="$store.state.caregiverCalendar.clients"
          label="name"
          :reduce="client => client.id"
          input-id="calendar"
          placeholder="Filter by Client"
          @input="filterShifts(false)"
        />
      </b-col>
    </b-row>

    <b-overlay
      :show="$store.state.caregiverCalendar.isLoading"
      spinner-variant="primary"
      spinner-type="grow"
      rounded="lg"
    >
      <b-card
        no-body
        class="mb-1"
      >
        <div class="card-body pl-1 pr-1 pb-0">
          <b-row>
            <!-- Calendar -->
            <b-col
              cols="12"
            >
              <div
                class="d-flex legend"
              >
                <span>
                  <div class="text-nowrap">
                    <feather-icon
                      icon="CompassIcon"
                      class="mr-75"
                      size="17"
                    />
                    <span class="title mr-75">
                      Legend
                    </span>
                  </div>
                </span>
                <span>
                  <span
                    v-for="legend in $store.state.caregiverCalendar.calendarOptions"
                    :key="legend.id"
                  >
                    <b-badge
                      :class="`bg-light-${legend.class}${1}
                      mr-75
                      legend-badge
                      outlined-${legend.id}
                      `"
                      @click="filterShifts(legend.id)"
                    >
                      <span class="badge-statue-font-color">
                        {{ legend.label }}
                      </span>
                    </b-badge>
                  </span>
                </span>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-1 p-0">
            <hr class="w-100">
          </b-row>

          <b-row class="px-1 mb-1">
            <b-col
              class="border rounded m-0 p-0"
              cols="12"
            >
              <div class="my-0 mt-1">
                <full-calendar
                  id="calendar"
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>

    </b-overlay>
    <task-bar />
  </div>
</template>
<!-- eslint-disable eqeqeq -->
<script>
import store from '@/store'
import FullCalendar from '@fullcalendar/vue'
import {
  ref, onUnmounted, watch, computed, onMounted,
} from '@vue/composition-api'
import {
  BRow, BCol, BBadge, BOverlay, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCaregiverCalendar from './useCaregiverCalendar.js'
import CaregiverCalendarStoreModule from './CaregiverCalendarStoreModule'
import TaskBar from './TaskBar.vue'

export default {
  components: {
    BBadge,
    BOverlay,
    BRow,
    BCol,
    BCard,
    vSelect,
    /* components */
    FullCalendar,
    TaskBar,
  },
  setup() {
    const client = ref('')

    const CAREGIVER_CALENDAR_APP_STORE_MODULE_NAME = 'caregiverCalendar'

    // Register module
    if (!store.hasModule(CAREGIVER_CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        CAREGIVER_CALENDAR_APP_STORE_MODULE_NAME,
        CaregiverCalendarStoreModule,
      )
    }

    onMounted(() => {
      document.querySelector('[dir] .legend .bg-light-show-all').style.filter = 'brightness(100%)'
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAREGIVER_CALENDAR_APP_STORE_MODULE_NAME)) { store.unregisterModule(CAREGIVER_CALENDAR_APP_STORE_MODULE_NAME) }
    })
    const {
      // methods
      getShifts,
      calendarOptions,
    } = useCaregiverCalendar()

    store.dispatch('caregiverCalendar/initializeSockJs', { id: store.state.caregiverCalendar.notificationSockId })
    store.dispatch('caregiverCalendar/initializeSockJs', { id: store.state.caregiverCalendar.alertSockId })

    /* hide overflowX */
    const istaskbar = ref(computed(() => store.state.caregiverCalendar.isTaskBar))

    watch(istaskbar, val => {
      const appContainer = document.querySelector('#app')
      if (!val) {
        appContainer.style.overflow = 'scroll'
        // appContainer.style.overflow = ''
      } else {
        appContainer.style.overflow = 'hidden'
      }
    })
    let activeFilters = []
    let showAllToggle = 0
    const legend = ref(null)
    const filterShifts = async legendId => {
      legend.value = legendId
      showAllToggle = 1
      if (legendId == '1') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-scheduled1')
          element.classList.add('outlined-1')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-scheduled1')
          element.classList.remove('outlined-1')
        }
      }
      if (legendId == '2') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-in-progress1')
          element.classList.add('outlined-2')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-in-progress1')
          element.classList.remove('outlined-2')
        }
      }
      if (legendId == '3') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-completed1')
          element.classList.add('outlined-3')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-completed1')
          element.classList.remove('outlined-3')
        }
      }
      if (legendId == '6') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-open-shift1')
          element.classList.add('outlined-6')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-open-shift1')
          element.classList.remove('outlined-6')
        }
      }
      if (legendId == '7') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-c-client1')
          element.classList.add('outlined-7')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-c-client1')
          element.classList.remove('outlined-7')
        }
      }
      if (legendId == '8') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
          element.classList.add('outlined-8')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
          element.classList.remove('outlined-8')
        }
      }
      if (legendId == '10') {
        if (activeFilters.includes(legendId)) {
          // activeFilters.pop(legendId)
          const index = activeFilters.indexOf(legendId)
          if (index > -1) { // only splice array when item is found
            activeFilters.splice(index, 1) // 2nd parameter means remove one item only
          }
          const element = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
          element.classList.add('outlined-10')
        } else {
          activeFilters.push(legendId)
          const element = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
          element.classList.remove('outlined-10')
        }
      }
      // eslint-disable-next-line no-const-assign
      if (legendId == null) {
        showAllToggle = 0
        // const elem = document.querySelectorAll('[dir] .legend .legend-badge')
        const showAll = document.querySelector('[dir] .legend .bg-light-show-all1')
        if (!showAll.classList.contains('outlined-0')) {
          showAllToggle = 1
          const element1 = document.querySelector('[dir] .legend .bg-light-scheduled1')
          element1.classList.remove('outlined-1')

          const element2 = document.querySelector('[dir] .legend .bg-light-in-progress1')
          element2.classList.add('outlined-2')

          const element3 = document.querySelector('[dir] .legend .bg-light-completed1')
          element3.classList.add('outlined-3')

          const element4 = document.querySelector('[dir] .legend .bg-light-open-shift1')
          element4.classList.add('outlined-6')

          const element5 = document.querySelector('[dir] .legend .bg-light-c-client1')
          element5.classList.add('outlined-7')

          const element6 = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
          element6.classList.add('outlined-8')

          const element7 = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
          element7.classList.add('outlined-10')

          const element8 = document.querySelector('[dir] .legend .bg-light-show-all1')
          element8.classList.add('outlined-0')

          activeFilters = []
          activeFilters = ['1']
        } else {
          showAllToggle = 0
          const element1 = document.querySelector('[dir] .legend .bg-light-scheduled1')
          element1.classList.add('outlined-1')

          const element2 = document.querySelector('[dir] .legend .bg-light-in-progress1')
          element2.classList.add('outlined-2')

          const element3 = document.querySelector('[dir] .legend .bg-light-completed1')
          element3.classList.add('outlined-3')

          const element4 = document.querySelector('[dir] .legend .bg-light-open-shift1')
          element4.classList.add('outlined-6')

          const element5 = document.querySelector('[dir] .legend .bg-light-c-client1')
          element5.classList.add('outlined-7')

          const element6 = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
          element6.classList.add('outlined-8')

          const element7 = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
          element7.classList.add('outlined-10')

          const element = document.querySelector('[dir] .legend .bg-light-show-all1')
          element.classList.remove('outlined-0')
          activeFilters = []
        }
      }
      if (legendId === false) {
        showAllToggle = 0
        const element1 = document.querySelector('[dir] .legend .bg-light-scheduled1')
        element1.classList.add('outlined-1')

        const element2 = document.querySelector('[dir] .legend .bg-light-in-progress1')
        element2.classList.add('outlined-2')

        const element3 = document.querySelector('[dir] .legend .bg-light-completed1')
        element3.classList.add('outlined-3')

        const element4 = document.querySelector('[dir] .legend .bg-light-open-shift1')
        element4.classList.add('outlined-6')

        const element5 = document.querySelector('[dir] .legend .bg-light-c-client1')
        element5.classList.add('outlined-7')

        const element6 = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
        element6.classList.add('outlined-8')

        const element7 = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
        element7.classList.add('outlined-10')

        const element = document.querySelector('[dir] .legend .bg-light-show-all1')
        element.classList.remove('outlined-0')
        activeFilters = []
      }

      if (activeFilters.length < 7 && showAllToggle > 0) {
        // console.log('testting 1')
        const element = document.querySelector('[dir] .legend .bg-light-show-all1')
        element.classList.add('outlined-0')
      } else {
        // console.log('testting 2')
        const element = document.querySelector('[dir] .legend .bg-light-show-all1')
        element.classList.remove('outlined-0')
      }

      // eslint-disable-next-line no-unused-vars
      const filteredShifts = await store.state.caregiverCalendar.shifts_raw.filter(f => {
        let keep = true
        if (client.value) keep = keep && f.client == client.value
        if (showAllToggle > 0) keep = keep && activeFilters.includes(f.status.toString())
        // keep = keep && f.status === legendId
        return keep
      })

      store.commit('caregiverCalendar/SET_SHIFTS', filteredShifts)
      // console.log('filteredShifts', filteredShifts)
    }

    const logMe = () => {
      console.log('log me')
    }

    return {
      getShifts,
      calendarOptions,
      pageTitle: 'Shift Calendar',
      client,
      filterShifts,
      legend,
      logMe,
    }
  },
}
</script>

<style scope lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "../clientcalendar/calendar.scss";

[dir] body{
    /* color: #33475B !important;

    h1, h2, h3, h4, h5{
      color: #33475B !important;
    } */
    .btn{
      font-weight: normal;
      font-size: 14px;
    }
}
.app-calendar-sidebar{
  background: transparent;
}
.fc-event-title{
  overflow: hidden !important;
  padding: 0px;
  margin: 0px;
}

[dir] #client-filter{
  background-color: #fff;
  background-clip: border-box;
}
[dir] .legend-badge {
  cursor: pointer;
  filter: brightness(100%);
}
[dir] .legend{
    .title{
      font-weight: 600;
      line-height: 24px;
      font-size: 13px;
    }
   .bg-light-completed1{
     background: #B7CF3E;
   }
   .bg-light-scheduled1{
     background: #01BCD6;
   }
   .bg-light-in-progress1{
     background: #FFCE2D;
   }
   .bg-light-m-clock-in1, .bg-light-m-clock-out1, .bg-light-attention-required1  {
    background: #EA5455;
   }
   .bg-light-open-shift1{
     background: #E63583;
   }
   .bg-light-c-client1{
     background: #B1BAC3;
   }
   .bg-light-c-caregiver1{
     background: #FF7D01;
   }
   .bg-light-pending-confirmation1{
     background: #7566E8;
   }
   .badge-statue-font-color{
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
}

[dir] .outlined-0 {
  background: #F4F8F9 0% 0% no-repeat padding-box !important;
  border: 1px solid #329D9C !important;
}
[dir] .outlined-0 > span {
  color: #329D9C !important;
}
[dir] .outlined-1 {
  background: #01BCD61F 0% 0% no-repeat padding-box !important;
  border: 1px solid #01BCD6 !important;
}
[dir] .outlined-1 > span {
  color: #01BCD6 !important;
}
[dir] .outlined-2 {
  background: #FFCE2D1F 0% 0% no-repeat padding-box !important;
  border: 1px solid #FFCE2D !important;
}
[dir] .outlined-2 > span {
  color: #FFCE2D !important;
}

[dir] .outlined-3 {
  background: #B7CF3E1F 0% 0% no-repeat padding-box !important;
  border: 1px solid #B7CF3E !important;
}
[dir] .outlined-3 > span {
  color: #B7CF3E !important;
}
[dir] .outlined-6 {
  background: #E736841F 0% 0% no-repeat padding-box !important;
  border: 1px solid #E73684 !important;
}
[dir] .outlined-6 > span {
  color: #E73684 !important;
}
[dir] .outlined-7 {
  background: #B1BAC41F 0% 0% no-repeat padding-box !important;
  border: 1px solid #B1BAC4 !important;
}
[dir] .outlined-7 > span {
  color: #B1BAC4 !important;
}
[dir] .outlined-8 {
  background: #FF7D011F 0% 0% no-repeat padding-box !important;
  border: 1px solid #FF7D01 !important;
}
[dir] .outlined-8 > span {
  color: #FF7D01 !important;
}
[dir] .outlined-10 {
  background: #EEECFD 0% 0% no-repeat padding-box !important;
  border: 1px solid #7566E8 !important;
}
[dir] .outlined-10 > span {
  color: #7566E8 !important;
}
// [dir] .legend-badge:hover {
//   filter: brightness(100%);
// }
</style>
