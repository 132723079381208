/* eslint-disable no-unused-expressions */
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export default function useCaregiverCalendar() {
  const shifts = ref()

  /*
    API request to get all shift and tasks
    Also set to tasks store module
  */

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  // const calendarsColor = {
  //   inprogress: 'primary',
  //   completed: 'success',
  //   'wont-complete': 'danger',
  //   'not-completed': 'warning',
  //   future: 'info',
  // }
  const calendarsColor = {
    1: 'scheduled',
    2: 'in-progress',
    3: 'completed',
    4: 'm-clock-in',
    5: 'm-clock-out',
    6: 'open-shift',
    7: 'c-client',
    8: 'c-caregiver',
    9: 'tentative-not-scheduled',
    10: 'pending-confirmation',
    11: 'attention-required',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: '',
      // end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: computed(() => store.state.caregiverCalendar.shifts),
    // events: computed(() => []),

    /*
      Hide time on title : false
      Diplay : true
    */
    displayEventTime: true,
    displayEventEnd: true,
    editable: false,
    eventResizableFromStart: false,
    dragScroll: false,
    dayMaxEvents: 2,
    navLinks: false,
    fixedWeekCount: false,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent.extendedProps.status]
      // console.log(calendarEvent)

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      document.querySelector('.fc-popover') ? document.querySelector('.fc-popover').classList.add('d-none') : '' /* remove MORE popover */
      store.commit('caregiverCalendar/SET_ISTASKBAR', true)
      store.commit('caregiverCalendar/SET_SHIFT', clickedEvent)
      // console.log('event', clickedEvent.extendedProps.shift_tasks)
      // console.log('event', clickedEvent.extendedProps.status)
      // store.dispatch('caregiverCalendar/sendSockMsg', { id: store.state.caregiverCalendar.notificationSockId, sockMsg: 'true' })
    },
  })

  store.dispatch('caregiverCalendar/getShifts') /* all get shift  */
  return {
    // variables
    shifts,

    // methods
    calendarOptions,
  }
}
