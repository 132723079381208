/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import Vue from 'vue'
import axios from '@axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import SockJS from 'sockjs-client'

const sock = new SockJS('https://socket.motivit.com:443/echo')

export default {

  namespaced: true,
  state: {
    shift: null,
    shifts_raw: [],
    shifts: [],
    isTaskBar: false,
    calendarOptions: [
      {
        id: null,
        class: 'show-all',
        label: 'Show All',
      },
      {
        id: '1',
        class: 'scheduled',
        label: 'Scheduled',
      },
      {
        id: '2',
        class: 'in-progress',
        label: 'In Progress',
      },
      {
        id: '3',
        class: 'completed',
        label: 'Completed',
      },
      // {
      //   id: '4',
      //   class: 'm-clock-in',
      //   label: 'Missed Clock-in',
      // },
      // {
      //   id: '5',
      //   class: 'm-clock-out',
      //   label: 'Missed Clock-out',
      // },
      {
        id: '6',
        class: 'open-shift',
        label: 'Open Shift',
      },
      {
        id: '7',
        class: 'c-client',
        label: 'Cancelled by Client',
      },
      {
        id: '8',
        class: 'c-caregiver',
        label: 'Cancelled by Caregiver',
      },
      // {
      //   id: '9',
      //   class: 'tentative-not-echeduled',
      //   label: 'Tentative/Not Scheduled',
      // },
      {
        id: '10',
        class: 'pending-confirmation',
        label: 'Pending Confirmation',
      },
      // {
      //   id: '11',
      //   class: 'attention-required',
      //   label: 'Attention Required',
      // },
    ],
    notificationSockId: 'coc-n01',
    alertSockId: 'coc-dw01',
    isLoading: true,
    clients: [],

  },
  mutations: {
    SET_SHIFTS_RAW: (state, payload) => state.shifts_raw = payload,
    SET_SHIFTS: (state, payload) => state.shifts = payload,
    SET_SHIFT: (state, payload) => state.shift = payload,
    SET_ISTASKBAR: (state, payload) => state.isTaskBar = payload,
    SET_LOADING: (state, payload) => state.isLoading = payload,
    SET_CLIENTS: (state, payload) => state.clients = payload,

  },
  actions: {

    getShifts: async state => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getShifts',
        // action: 'test',
        userId: JSON.parse(localStorage.userData).id,
      })
      formData.append('data', data)

      try {
        const response = await state.dispatch('request', formData)
        // eslint-disable-next-line no-shadow
        const shifts = response.data.response.data
        // console.log('shifts', shifts)

        shifts.forEach((shift, index) => {
          shifts[index].title = ` ${shift.client_fullname}`
          // shifts[index].title = `${moment(shift.start_time, ['HH.mm']).format('ha')}-${moment(shift.end_time, ['HH.mm']).format('ha')} ${shift.client_fullname}`
          // shifts[index].title = `${shift.id} ${moment(shift.start_time, ['HH.mm']).format('ha')}-${moment(shift.end_time, ['HH.mm']).format('ha')} ${shift.client_fullname}`
          /* display the cancelled shift based on days before now */
          // const addedDay = 1
          // // const addedDay = 7

          // const dateShowCancelldShift = moment().add((addedDay - 1), 'days')

          // shifts[index].isAbleToCancel = dateShowCancelldShift <= moment(shift.start)
          // console.log(dateShowCancelldShift)
        })
        // state.commit('SET_SHIFTS_RAW', shifts)
        // state.commit('SET_SHIFTS', shifts)
        // console.log('shifts', shifts)
        state.commit('SET_SHIFTS', shifts.filter(f => !['4', '5', '9', '11'].includes(f.status)))
        state.commit('SET_SHIFTS_RAW', shifts.filter(f => !['4', '5', '9', '11'].includes(f.status)))
        await state.dispatch('getClientsInShifts')
        state.commit('SET_LOADING', false)
        // console.log('compeleted', state.state.shifts.filter(f => f.status === '3'))
      } catch (error) {
        // console.log(error)
        state.commit('SET_LOADING', false)
        state.dispatch('popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: true,
          // timer: 3500,
          customClass: {
            confirmButton: 'btn-primary w-25',
          },
        })
      }
    },

    getClientsInShifts: async ctx => {
      const tempclients = await Array.from(new Set(ctx.state.shifts.map(a => a.client))).map(client => ctx.state.shifts.find(a => a.client === client))
      const clients = await tempclients.map(f => ({ id: f.client, name: f.client_name }))
      ctx.commit('SET_CLIENTS', clients)
      // console.log('caregivers', caregivers)
    },

    request: async (state, payload) => {
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}caregiver/action`, payload,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
        return response
      } catch (error) {
        return error
      }
    },

    popupNotif: (state, payload) => {
      Swal.fire(payload)
    },

    initializeSockJs: (state, payload) => {
      sock.onopen = () => {
        // console.log('Connection established.')
      }

      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === payload.id) {
          state.dispatch('getSockMsg', data.msg)
        }
      }

      sock.onclose = () => {
        SockJS.call(sock, sock.url)
      }
    },

    getSockMsg: (state, sockMsg) => {
      if (sockMsg) {
      // Trigger reload or something here
        // console.log(sockMsg)
        state.dispatch('getShifts')
      }
    },

    sendSockMsg: (state, payload) => {
      // console.log('payload', payload)
      sock.send(JSON.stringify({ id: payload.id, msg: payload.sockMsg }))
    },

  },
}
